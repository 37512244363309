const SCROLL_TRACK_RES = 1024;
const OFFEST_TOP = 150;
const OFFSET_BOTTOM = 150;
let activeSection = "about";

// The scroll event logic
function handleScroll(sections) {
  sections.forEach((section) => {
    const rect = section.getBoundingClientRect();
    // check if the last section's bottom within the defined offset from the bottom of the viewport
    // OR its top is withing 50% of the offset from the top of the viewport
    if (
      section.id === "training" &&
      (rect.top < window.innerHeight / 2 ||
        rect.bottom < window.innerHeight + OFFSET_BOTTOM)
    ) {
      updateActiveSection(section.id);
      return;
    }

    if (rect.top <= OFFEST_TOP && rect.bottom > OFFEST_TOP) {
      updateActiveSection(section.id);
    }
  });
}

function updateActiveSection(sectionId) {
  // if the active section is not the current section, update it
  if (activeSection !== sectionId) {
    activeSection = sectionId;
    // update the active section in the menu
    document
      .querySelector("#sections-nav li a.active")
      .classList.remove("active");
    document
      .querySelector(`#sections-nav li a[href="#${activeSection}"]`)
      .classList.add("active");
  }
}

// Attach the scroll event listener
if (window.innerWidth >= SCROLL_TRACK_RES) {
  document.addEventListener("DOMContentLoaded", () => {
    // Attach the debounced event to the main element
    const sections = document.querySelectorAll("main > section");
    document.addEventListener("scroll", () => handleScroll(sections));
  });
}
